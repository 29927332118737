<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title class="text-xs-h5 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 text-h6 success--text"
        >Уведомления</v-toolbar-title
      >
    </v-toolbar>
    <div v-if="notifications.length > 0">
      <v-card
        v-for="notification in notifications"
        v-bind:key="notification.id"
        width="100%"
        class="mt-5"
        :color="notification.seen ? 'white' : 'green lighten-4'"
      >
        <v-card-title v-if="notification.type == 'new_task'"
          ><a class="text-xs-h5 text-sm-h6 text-md-h6 text-lg-h5 text-xl-h5 text-body-2" @click="HasSeenNotification(notification)">
            Новое задание от {{ notification.from_user.first_name }}
          </a>
          <v-spacer></v-spacer><span v-if="!$vuetify.breakpoint.xs">{{ notification.created_at | date_formatter }}</span>
          <v-icon @click="RemoveNotification(notification)" right
            >mdi-close</v-icon
          >
          <span class="text-caption" v-if="$vuetify.breakpoint.xs">{{ notification.created_at | date_formatter }}</span>
        </v-card-title>
        <v-card-title v-if="notification.type == 'accepted_task'"
          ><a class="text-xs-h5 text-sm-h5 text-md-h4 text-lg-4 text-xl-4 text-body-2" @click="HasSeenNotification(notification)">
            Задание принято
            {{ notification.from_user.first_name }}
          </a>
          <v-spacer></v-spacer><span v-if="!$vuetify.breakpoint.xs">{{ notification.created_at | date_formatter }}</span>
          <v-icon @click="RemoveNotification(notification)" right
            >mdi-close</v-icon
          >
          <span class="text-caption" v-if="$vuetify.breakpoint.xs">{{ notification.created_at | date_formatter }}</span>
        </v-card-title>
        <v-card-title v-if="notification.type == 'completed_task'"
          ><a class="text-xs-h5 text-sm-h5 text-md-h4 text-lg-4 text-xl-4 text-body-2" @click="HasSeenNotification(notification)">
            {{ notification.from_user.first_name }} выполнил(a) задание по
            {{ notification.individual_task.name }}
          </a>
          <v-spacer></v-spacer><span v-if="!$vuetify.breakpoint.xs">{{ notification.created_at | date_formatter }}</span>
          <v-icon @click="RemoveNotification(notification)" right
            >mdi-close</v-icon
          >
          <span class="text-caption" v-if="$vuetify.breakpoint.xs">{{ notification.created_at | date_formatter }}</span>
        </v-card-title>
      </v-card>
    </div>

    <v-dialog
      v-if="notifications.length > 0"
      v-model="dialog"
      max-width="500px"
    >
      <v-card v-if="notification">
        <v-card-title>
          {{ notification.individual_task.name }} <v-spacer></v-spacer>
          <v-btn
            v-if="
              notification.type == 'new_task' &&
              !notification.individual_task.is_accepted
            "
            text
            color="success"
            @click="AcceptTask(notification.individual_task)"
            >Принять</v-btn
          >
        </v-card-title>
        <v-card-subtitle
          >от {{ notification.from_user.first_name }}</v-card-subtitle
        >
        <v-card-title>Подробности</v-card-title>
        <v-card-text>
          {{ notification.individual_task.details }}
        </v-card-text>
        <v-card-title>Начало</v-card-title>
        <v-card-text>
          {{ notification.individual_task.start | date_formatter }}
        </v-card-text>
        <v-card-title>Окончание</v-card-title>
        <v-card-text>
          {{ notification.individual_task.end | date_formatter }}
        </v-card-text>

        <template v-if="notification.type == 'completed_task'">
          <v-card-title v-if="notification.individual_task.comment">
            Комментарий
          </v-card-title>
          <v-card-text v-if="notification.individual_task.comment">
            {{ notification.individual_task.comment }}
          </v-card-text>
          <v-card-title v-if="notification.individual_task.file">
            Файл
          </v-card-title>
          <v-card-text v-if="notification.individual_task.file">
            <a
              :href="api + notification.individual_task.file"
              class="text-decoration-none"
              >{{ notification.individual_task.file }}</a
            >
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
    <div v-else>
      <v-card tile flat>
        <v-card-title> Нет уведомлений </v-card-title>
      </v-card>
    </div>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      absolute
      right
      top
      color="green"
      elevation="6"
      multi-line
      tile
      class="snackbar"
      transition="slide-x-reverse-transition"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import { INDIVIDUAL_TASK_PUT } from "@/store/actions/task";
import moment from "moment";
import {
  NOTIFICATION_GET,
  NOTIFICATION_DELETE,
  NOTIFICATION_PUT,
  NOTIFICATION_POST
} from "@/store/actions/notification";
export default {
  data() {
    return {
      notifications: [],
      notification: null,
      snackbar: false,
      snackbar_text: null,
      dialog: false,
      api: process.env.VUE_APP_API_URL.slice(0, -1),
    };
  },
  filters: {
    date_formatter: function (date) {
      return moment(date).format("MMMM Do HH:mm");
    },
  },
  methods: {
    async getNotifications() {
      let resp = await this.$dispatch(
        NOTIFICATION_GET,
        this.$store.getters.profile.id
      );
      this.notifications = resp.result;
      return resp;
    },
    async RemoveNotification(notification) {
      let resp = await this.$dispatch(NOTIFICATION_DELETE, notification.id);
      if (resp.status == "delete") {
        this.snackbar = true;
        this.snackbar_text = "Уведомление успешно удалено"
        let resp2 = await this.$dispatch(NOTIFICATION_GET, {
          id: this.$store.getters.profile.id,
          filter: "seen=false",
        });
        this.$store.commit("notifications", resp2.result);
        this.getNotifications()
      }
    },
    async HasSeenNotification(notification) {
      this.notification = notification;
      this.dialog = true;
      let to_users = [];
      for (let u in notification.to_users) {
        to_users.push(notification.to_users[u].id);
      }
      let resp = await this.$dispatch(NOTIFICATION_PUT, {
        id: notification.id,
        seen: true,
        from_user: notification.from_user.id,
        to_users: to_users,
      });
      if (resp.success) {
        this.getNotifications();
        let resp2 = await this.$dispatch(NOTIFICATION_GET, {
          id: resp.result.id,
          filter: "seen=false",
        });
        this.$store.commit("notifications", resp2.result);
      }
    },
    async AcceptTask(event) {
      let resp = await this.$dispatch(INDIVIDUAL_TASK_PUT, {
        id: event.id,
        is_accepted: true,
        status: "current_task",
      });
      if (resp.success) {
        let resp2 = await this.$dispatch(NOTIFICATION_POST, {
          from_user: this.$store.getters.profile.id,
          to_users: [event.from_user.id],
          individual_task: event.id,
          type: "accepted_task",
        });
        this.snackbar_text = "Задача перемещена в категорию текущих задач";
        this.snackbar = true;
        this.getNotifications()
      } else {
        event.is_accepted = false;
      }
    },
  },
  async created() {
    let data = await this.getNotifications();
    this.notification = data.result[0];
    moment.locale("ru");
  },
};
</script>

<style>
</style>